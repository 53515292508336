/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    blockquote: "blockquote",
    em: "em",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "En ", React.createElement(_components.a, {
    href: "http://nomada.blogs.com/jfreire/2012/12/manifiesto-diseno-codigo-abierto.html?utm_source=feedburner&utm_medium=feed&utm_campaign=Feed%3A+JuanFreire+%28Juan+Freire%29"
  }, "el manifiesto para un diseño de productos de código abierto"), " de Juan Freire encontramos muchas de las claves para este tipo de diseño, una de las que más destaca es la del diseño modular, ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2012/08/26/diseno-modular/"
  }, "de la que ya hablé en este blog"), ", además me gusta la idea del objeto físico como ensamblaje de código fuente, material y contextos, o en sus propias palabras:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Lo físico parecía inaccesible, su finitud y materialidad lo condenaba a la tragedia si lo gobernábamos como un procomún. Pero todo objeto físico es en realidad un ", React.createElement(_components.em, null, "assemblage"), " de materiales, códigos y narrativas."), "\n"), "\n", React.createElement(_components.p, null, "Otra idea que tomo prestada del manifiesto es la de incorporar restricciones que generen nuevas libertades, cosa que me sirve para introducir el siguiente tema que quería tratar.", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2013/01/os-grid.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75.31645569620254%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe9LDQP/xAAWEAADAAAAAAAAAAAAAAAAAAAQESD/2gAIAQEAAQUChD//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAcEAABBAMBAAAAAAAAAAAAAAABABARUSFxsfD/2gAIAQEAAT8hk0s7Yj0oW63/2gAMAwEAAgADAAAAEPPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGRABAQEBAQEAAAAAAAAAAAAAAREhADFB/9oACAEBAAE/EEE+brfOxUqXxZxppOrEvt4iOjci84ITv//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"OS-grid\"\n        title=\"OS-grid\"\n        src=\"/static/3e30e95f834fd0f3ec1e2677c4cd6c01/828fb/os-grid.jpg\"\n        srcset=\"/static/3e30e95f834fd0f3ec1e2677c4cd6c01/ff44c/os-grid.jpg 158w,\n/static/3e30e95f834fd0f3ec1e2677c4cd6c01/a6688/os-grid.jpg 315w,\n/static/3e30e95f834fd0f3ec1e2677c4cd6c01/828fb/os-grid.jpg 630w,\n/static/3e30e95f834fd0f3ec1e2677c4cd6c01/4b190/os-grid.jpg 800w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://openstructures.net/"
  }, "OpenStructures"), ", es un repositorio de diseños libres que parte de esta misma idea, pongamos restricciones de diseño, utilicemos ", React.createElement(_components.a, {
    href: "http://openstructures.net/pages/9#deel2b"
  }, "una norma libre"), ", la misma para todos, y conseguiremos un sistema de construcción por diseño modular accesible para todo el mundo. De esta forma se consiguen libertades que sin estas restricciones previas no podrían conseguirse, algo parecido a las licencias duras de software libre como la GPL.Como proyecto es interesante ver hacia donde lleva todo esto, un experimento muy interesante, además estoy seguro de que surgirán nuevos proyectos de este tipo, como ", React.createElement(_components.a, {
    href: "http://opensourceecology.org/"
  }, "OSE"), " y otros. Bienvenidos a 2013, bienvenidos al diseño libre."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
